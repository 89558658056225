
.body{

    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";

}

.arrowright {
    border           : solid grey;
    border-width     : 0 2px 2px 0;
    display          : inline-block;
    padding          : 3px;
    transform        : rotate(-45deg);
    -webkit-transform: rotate(-45deg)
}

.calendericon {
    width    : 15px;
    height   : 15px;
    position : absolute;
    margin-left: -15px;
}

.date-range {
    padding: 10px;
}
.date-left{
    display: contents;
    float:left;
}
.date-right{
    float:right;
}

.date-range input {
    width      : 25%;
    margin-left: 5px;
    border     : none;
}

.date-range input:focus {
    border: none;
}

.outer {
    width        : 60%;
    height       : 10rem;
    margin       : auto;
    margin-top   : 2rem;
    box-shadow   : 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    border-radius: 4px 4px;
    border       : 1px solid #D9D7D7;
    word-break   : break-all;
}



@media only screen and (max-width:768px)  {
    .outer{
        width:80%!important;
         margin-bottom: 5rem!important;
         

    }

    .MuiSvgIcon-fontSizeInherit {
        font-size: inherit;
        width: 45px;
        height: 45px;
    }
    .small-width {
        width: 80%!important;
    }
    .recharts-responsive-container .Piechart-container-30{
        height: auto!important;
    }
    .company {
        font-size: 15px!important;
    }
    
    
    .companytext {
        text-align: center;
        width: 100%!important;
        height:auto!important;
    }
    .company p{
        margin-left: 0px!important;
    }
    .date-range input {
        width: 29%;
        margin-left: 5px;
        border: none;
    }
    .companyicon {
        width:100%!important;

    }
    .inner2{
        font-size: 1rem!important;
        line-height: 1px!important;
    }
    .inner21{
        width: 100%!important;
        display: flex!important;
        flex-direction: column!important;
        
    }
    .inner21 .margin-class{
        margin: 15px 2px!important;
    }
    .inner22{
        width: 100%!important;
    }
    .inner221main {
        width: 90%!important;
        height: 100%!important;
    }
    .inner221 {
        height: auto!important;
    }
    .inner222 {
        margin: 10px!important;
    }
    .timeTakenCard{
        width: 80%!important;
        height: auto!important;

    }
    .companyTimeTaken{
        font-size: 15px!important;
        line-height: 26px!important;
    }
    .companyTimeTaken section{
        font-size: 15px!important;
    }
    table{
    width: 100%!important;   
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-size: 12px!important;
    line-height: 26px!important;
    }
    .outer .short{
        width:80%!important;
    }
    .buttons {
        margin-top: 0rem!important;
        margin-bottom: 5rem!important;
    }
    .buttons input {
        height: 30px!important;
        margin: 10px;
        padding: 5px!important;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        line-height: 18px!important;
        font-size: 12px!important;
        box-shadow: 0px 2px 2px rgb(0 0 0 / 24%);
        border-radius: 2px;
    }
    .outer h4 {
        padding-left: 10%;
        font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
        font-style: normal;
        font-weight: bold;
        font-size: 18px!important;
        line-height: 26px!important;
    }
    .outer p {
        padding-left: 10%;
        font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
        font-style: normal;
        font-weight: normal;
        font-size: 16px!important;
        line-height: 3px;
    }
    .compnayLevelRightPnale {
        margin: -110px 0px 0px 20px!important;
    }
    .piecolor {
        width: 100%!important;
    }
    .outer-box{
        
    height: 26rem!important;
    }
    .input1 {
        margin: 5px!important;
        padding: 2px!important;
    }
    .Piechart-container-23 {
        width: 100%!important;
        height: auto!important;
    }
    .recharts-wrapper .recharts-surface{
        width: 100%!important;
        height: auto!important;
    }
    .recharts-wrapper{
        display: flex;
        justify-content: center;
        width: 100%!important;
        height: auto!important;
    }
    .recharts-responsive-container .Piechart-container-30{
        height:auto!important;
    }
    .outer .short .small-width {
        width: 80%!important;
    }
    
}
@media only screen and (max-width: 768px)
{
    .recharts-wrapper .recharts-surface {
        width: 65%!important;
        height: auto!important;
    }
    .outer .short .small-width {
        width: 80%!important;
    }
    /* .MuiBox-root-29 {
        height:auto!important;
    } */
    /* .nice-dates-popover {
    width: 75%!important;
    } */
    .recharts-responsive-container .Piechart-container-30{
        height:auto!important;
    }
    .nice-dates-popover {
        width: 75%!important;
    }
    
}
@media (max-width: 959.95px){
    .Piechart-container-30 {
        width: 100%;
        height: auto!important;
    }
    
}


@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    .outer{
        width: 60%!important;   
    }
    .outer-box1{
        width: 80%!important;   
    }
    .outer-box{
        width: 80%!important;   
    }
    .timeTakenCard{
        width: 60%!important;
        height: auto!important;
    }
    .recharts-wrapper .recharts-surface {
        width: 100%;
        height: auto!important;
    }
    .recharts-wrapper .recharts-surface {
        width: 100%!important;
        height: auto!important;
    }
    table {
        width: 100%!important;
        font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
        font-style: normal;
        font-size: 12px!important;
        line-height: 26px!important;
    }
    
}
@media only screen and (min-width: 1024px)   {
    .outer-box{
        width: 70%!important;   
    }
    .outer-box1{
        width: 70%!important;   
    }
   
    .timeTakenCard{
        width: 60%!important;
        height: auto!important;
    }
    .nice-dates-popover {
        width: 30%!important;
    }
}

.timeTakenCard{
    height       : 22.5rem;
    /* width        : 50%; */
    /* height       : 10rem; */
    margin       : auto;
    margin-top   : 2rem;
    box-shadow   : 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px 4px;
    border       : 1px solid #D9D7D7;
    word-break   : break-all;
    width     : 50%;
    /* height    : 31rem; */
    background-color: white;
    word-break: break-all;
    overflow  : hidden;
}

.inner1 {
    background: #E5E5E5;
    height    : 1rem;
    padding   : 1rem;
    word-break: break-all;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.inner2 {
    max-height: 7rem;
    font-size : 1rem;
}

.inner21 {
    width                : 65%;
    /* height               : 7rem; */
    word-break           : break-all;
    display              : grid;
    grid-gap             : auto;
    grid-template-columns: 1fr 1fr;
    float                : left;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.inner22 {
    width     : 35%;
    height    : 7rem;
    word-break: break-all;
    float     : right;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}


.inner221 {
    height         : 3.5rem;
    word-break     : break-all;
    display        : flex;
    justify-content: center;
}

.inner221main {
    width        : 80%;
    height       : 85%;
    margin       : auto;
    border-radius: 4px;
    border       : 1px solid rgb(182, 177, 177);
}

.inner222 {

    margin-right: 48px;
    float: right;
    height: 3.5rem;
    /* height         : 3.5rem;
    word-break     : break-all;
    display        : flex;
    justify-content: center; */
}


.input1 {
    width        : 96px;
    height       : 28px;
    margin-top   : 10px;
    background   : #FFFFFF;
    border-radius: 4px;
    border       : 1px solid grey;
    padding      : 0px 0px;
    font-size    : 15px;
    display      : inline-block;
    margin-left  : 15px;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.input2 {
    background: #3F51B5;
    color     : white;
}

.company {
    height        : 4.5rem;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style    : normal;
    font-weight   : normal;
    font-size     : 24px;
    line-height   : 26px;
    display       : flex;
    align-items   : center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
}

.company p {
    margin-left: 100px;
}

.companyicon {
    /* background-color: cyan; */
    width      : 30%;
    font-size  : 22px;
    height     : 100%;
    display    : flex;
    align-items: center;
}

.companytext {
    /* background-color: yellow; */
    width : 70%;
    height: 100%;
}

.companyicon span {
    margin-left        : 65%;
    margin-right       : 15%;
    /* background-color: black; */
}

/* 
By Nishant */
/* .asdf {
    margin-top: 1rem;
} */

.bottomMargin{
    margin-bottom: 4rem;
}

.innerCardMargin{
    margin: -50px 0 0 0;
}

.short {
    /* width     : 55%!important; */
    /* height    : 31rem; */
    height:auto!important;
    background-color: white;
    word-break: break-all;
    overflow  : hidden;
}


.pie {
    background-color: white;
    width : 70%;
    height: 0%;
    float : left;
}

.pie h4 {
    padding-left: 10%;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style  : normal;
    font-weight : bold;
    font-size   : 36px;
    line-height : 26px;
}

.pie p {
    padding-left: 10%;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style  : normal;
    font-weight : normal;
    font-size   : 24px;
    line-height : 26px;
}


.piecolor {
    background-color: white;
    float           : right;
    width           : 30%;
    height          : 100%;
}
.fonts{ 
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}

.compnayLevelRightPnale{
    margin: 135px 0px 0px 0px;
  }

.countMargin{
    margin: 7px 0px 26px -66px;
}
.MuiBox-root-170 b{
    margin-left:-23px;
}
.MuiBox-root-36 b {
    margin-left:-32px;
}
.MuiBox-root-44 b{
    margin-left:-20px;
}
.MuiBox-root-162 b{
    margin-left:-36px;
}
.companyTimeTaken {
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style         : normal;
    font-weight        : normal;
    margin        : 6%;
    margin-top         : 5%;
    font-size          : 24px;
    line-height        : 26px;
    color              : #454545;
    /* background-color: red; */
}

.companyTimeTaken section {
    font-weight: bold;
    font-size  : 36px;
    line-height: 26px;
}

table {
    border-collapse: collapse;
    width          : 80%;
    max-width      : 80%;
    height         : 50%;
    margin         : auto;
    margin-top     : 4%;

    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style    : normal;
    font-weight   : normal;
    font-size     : 12px;
    line-height   : 26px;
}

table td,
table th {
    /* padding      : 8px; */
    padding-left : 5px;
    /* padding-right: 4%; */

}

table tr th {
    text-align   : left;
    border-bottom: 1px solid #d1cfcf;
    border-left  : 1px solid #d1cfcf;
}

table tr th:first-child {
    border-left: 0;
}

table tr td {
    border-left: 1px solid #d1cfcf;
    ;
}

table tr td:first-child {
    border-left: 0;
}

table {
    border-collapse: collapse;
}

.companyExpand {
    width           : 100%;
    height          : 48px;
    background-color: #454545;
    border-radius   : 0px 0px 4px 4px;
    color           : white;
    font-family     : Roboto;
    font-style      : normal;
    font-weight     : bold;
    font-size       : 12px;
    line-height     : 26px;
    display         : flex;
    align-items     : center;
    justify-content : center;
}

.ActionTaken {
    background-color: lightgrey;
    width           : 100%;
    height          : 100%
}

.rating {
    height: auto;
    margin: 35px;
    display: flex;
    justify-content: flex-start;
}

.buttons {
    width           : 45%;
    /* height          : 10rem; */
    height          : 0rem; 
    margin          : auto;
    margin-top      : 5rem;
    margin-bottom   : 10rem;
    display         : flex;
    align-items     : center;
    justify-content : center;
}

.buttons input {
    height : 60px;
    margin : 10px;
    padding: 10px;
    width: 224px;
    line-height: 36px;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style   : normal;
    font-weight  : bold;
    font-size    : 24px;
    box-shadow   : 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 2px;
}
.MuiBox-root .MuiBox-root-29 {
    height: auto!important;
}
.MuiBox-root-27 {
    display: flex;
    height: 1rem;
    margin-bottom: 3.7rem!important;
}
/* .MuiSvgIcon-root{
width: 54px!important;
height: 54px!important;
} */
.MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
    width: 54px;
    height: 54px;
}
.recharts-responsive-container .Piechart-container-30{
    display: flex;
    flex-direction: column-reverse;
}
.outer h4 {
    padding-left: 5%;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 26px;
}
.outer p {
    padding-left: 5%;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 12px;
}
.MuiBox-root-88{
    width: 0rem!important;
}

.outer .companyExpand p{
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px!important;
    line-height:26px!important;
}

.space-btm{
    margin: 25px !important;
}

.my-custom-scrollbar {
    position: relative;
    height: 200px;
    overflow-y: scroll;
    display: block;
}

.date-stye {
    font-size: 10px;
    line-height: 16px;
    padding-left: 3%;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}