.my-popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 37%;
    padding: 30px 80px;
  }
  @media only screen and (max-width: 480px) {
    .my-popup-content {
      top: 30%;
    margin: 20px!important;
    width: 100%;
    padding: 20px 30px;
    height: max-content;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    .my-popup-content {
      top: 30%;
      margin: 50px 150px!important;
    width: 100%;
    padding: 20px 30px;
    height: max-content;
    }
  }
  @media only screen and (min-width: 767px) and  (max-width: 1100px) {
    .my-popup-content {
    top: 30%;
    margin: 20px 225px!important;
    width: 100%;
    padding: 20px 30px;
    height: max-content;
    }
  }
.my-popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role='tooltip'].my-popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
  
.my-popup-overlay {
  /* background: rgba(0, 0, 0, 0.16); */
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255 / 90%);
  z-index: 2;
  cursor: pointer;
  }
  [data-popup='tooltip'].my-popup-overlay {
    background: transparent;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.font-related{
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}